.burgerList {
  @apply w-screen bg-white z-10 shadow-burger px-4;
}

.burgerList[data-side='top'] {
  animation-name: slideUp;
}

.burgerList[data-side='bottom'] {
  animation-name: slideDown;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.burgerLinks {
  @apply text-primary py-2 hover:opacity-70;
}
