.loginInput {
  @apply border-b-transparent rounded-t-md;
}

.passwordInput {
  @apply rounded-b-md;
}

.button {
  @apply mt-5 p-3.5 rounded-md bg-blue-500 hover:bg-blue-600 disabled:bg-gray-400 disabled:hover:bg-gray-400;
  height: 52px;
  min-height: 52px;
  max-height: 52px;
}
