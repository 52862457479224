.breadcrumbsItem::before {
    content: '/';
    padding: 0 0.5rem;
    color: rgb(159, 171, 176);
}

.breadcrumbsItem:first-child:before {
    content: '';
    padding: 0;
}

.breadcrumbsItem .disabled {
    pointer-events: none;
}
