/* for dark theme */
/*:root {*/
/*  --foreground-rgb: 0, 0, 0;*/
/*  --background-start-rgb: 214, 219, 220;*/
/*  --background-end-rgb: 255, 255, 255;*/
/*}*/

/*@media (prefers-color-scheme: dark) {*/
/*  :root {*/
/*    --foreground-rgb: 255, 255, 255;*/
/*    --background-start-rgb: 0, 0, 0;*/
/*    --background-end-rgb: 0, 0, 0;*/
/*  }*/
/*}*/

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 204 99% 32%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9;
  }

  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@font-face {
  font-family: 'SF Pro Icons';
  src: url('/fonts/SF_Pro_Icons_Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* Aeroport font */

@font-face {
  font-family: 'Aeroport';
  src: url('/fonts/Aeroport-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* Golos font */

@font-face {
  font-family: 'Golos Text';
  src: url('/fonts/GolosText-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Golos Text';
  src: url('/fonts/GolosText-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Golos Text';
  src: url('/fonts/GolosText-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'gotham pro';
  src: url('/fonts/GothamPro-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'gotham pro';
  src: url('/fonts/GothamPro.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: avenir;
  src: url('/fonts/Avenir-Roman.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: avenir;
  src: url('/fonts/Avenir-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: stem;
  src: url('/fonts/Stem-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: stem-regular;
  src: url('/fonts/Stem-Regular.woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: stem;
  src: url('/fonts/Stem.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body,
#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
}

.container {
  width: 100%;
  @apply mx-auto px-4;
}

@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}

@media (min-width: 1140px) {
  .container {
    max-width: 1140px;
  }
}

.burger-menu {
  @apply relative flex h-full w-5 flex-col items-center justify-center opacity-[0.8] md:hidden;
}

.burger-menu-arrow {
  @apply absolute h-px w-full bg-black transition-all duration-100;
}

.burger-menu-arrow:first-child {
  @apply -mt-2;
}

.burger-menu-arrow:last-child {
  @apply mt-2;
}

.burger-menu[aria-expanded='true'] .burger-menu-arrow:first-child {
  transform: rotate(45deg) translate(5px, 5px);
  margin-top: -16px;
}

.burger-menu[aria-expanded='true'] .burger-menu-arrow:last-child {
  transform: rotate(-45deg) translate(7px, -8px);
  margin-top: 19px;
}

.doubled-arrow {
  background: #fff
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center/8px 10px;
}

select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  appearance: none;
}

html {
  scroll-behavior: smooth;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: #f1f1f1;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background: rgba(170, 170, 170, 0.8);
}

*::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(155, 155, 155, 0.4);
}
/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: rgba(170, 170, 170, 0.8);
}

* {
  min-width: 0;
}

.ui-status {
  display: block;
  width: 16px;
  min-width: 16px;
  height: 16px;
  border: 3px solid #d3d3d3;
  border-radius: 50%;
}

.ui-status--green {
  border-color: #2b9851;
}

.ui-status--red {
  border-color: #dd2f41;
}

.bold_b b {
  font-family: stem;
}

.active-search-filter {
  background: #e8f1fa;
  font-weight: 500;
  position: relative;
}

.active-search-filter::after {
  content: '\2715';
  font-weight: bold;
  cursor: pointer;
  margin-left: 5px;
}

.active-search-filter.flex-col::after {
  position: absolute;
  margin-left: 0;
  right: 8px;
  top: 4px;
}

.animation {
  width: 600px;
  height: 600px;
}

.bold_b b {
  font-family: stem;
}

.font-golos {
  font-family: 'Golos Text';
}

.font-stem {
  font-family: stem, sans-serif !important;
}

.font-stem-medium {
  font-weight: 500;
}

.font-stem-medium-b b {
  font-family: stem-regular;
  font-weight: 700;
}

@media screen and (max-width: 500px) {
  .font-stem-medium-b b {
    font-weight: 500;
  }
}

.banner-link::after {
  font-family: 'SF Pro Icons', serif;
  color: inherit;
  display: inline-block;
  font-style: normal;
  font-weight: inherit;
  line-height: 1;
  text-decoration: none;
  position: relative;
  z-index: 1;
  content: '';
  padding-left: 0.3em;
  top: 0;
  font-size: 110%;
}

.company-tabs::-webkit-scrollbar {
  display: none;
}
.company-tabs {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#tooltip::before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background: inherit;
  box-shadow: -4px -4px 20px 0 rgba(21, 52, 86, 0.1);
  position: absolute;
  top: -5px;
  margin-top: 2px;
  left: 10px;
  transform: rotate(45deg);
  z-index: 101;
}

.child-span-leading > span {
  line-height: 14px;
}

.barWrapper {
  --count: 1;
  display: grid;
  grid-template-rows: repeat(100, 1fr);
  grid-column-gap: 1px;
  max-width: 300px;
  height: 25px;
  width: calc(var(--count) * 8px);
  margin-top: -13px;
}

.barWrapper_taxes {
  display: grid;
  grid-column-gap: 1px;
  max-width: 100%;
  height: 100px;
  width: 100%;
}

.bar {
  grid-row-end: 102;
  position: relative;
  background: #99cafe;
}

.bar.active {
  background: #02316e;
}

.bar.active_taxes_min {
  background: #dddddd;
}

.bar.active_taxes_max {
  background: #dddddd;
}

.triangle {
  margin-left: 0.5em;
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transition: all 0.3s ease-in;
  opacity: 1;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  user-select: none;
}
.triangle.rotated {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-bottom: 0.15em;
}

.linear-gradient {
  background: linear-gradient(0deg, #fff 50%, hsla(0, 0%, 100%, 0));
  background: -webkit-linear-gradient(90deg, #fff 50%, hsla(0, 0%, 100%, 0));
}

.arrow-after::after {
  font-family: 'SF Pro Icons';
  color: inherit;
  display: inline-block;
  font-style: normal;
  font-weight: inherit;
  line-height: 1;
  text-decoration: none;
  position: relative;
  z-index: 1;
  content: '';
  padding-left: 0.3em;
  top: 0;
  font-size: 110%;
}

.with-dots:first-child::before {
  content: '';
}

.with-dots::before {
  content: '·';
  @apply mr-2;
}

.customMargins > span:first-of-type {
  min-width: var(--min-width);
  margin-right: 0.5rem;
}

.forTable {
  margin-left: 0.5em;
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  margin-top: 0.3em;
  transition: all 0.3s ease-in;
  opacity: 0.5;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.forTable.rotated {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-top: -0.3em !important;
}

.sticky_container {
  bottom: -116px;
  z-index: 51;
  transition: bottom 0.5s ease;
}

.sticky_container.show {
  bottom: 0;
}

.sticky_container.hide {
  bottom: -116px;
}

.sticky_form {
  padding: 20px 20px;
  background: #fff;
  width: 95%;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 0 16px 1px rgb(0 0 0 / 10%);
}

.buy_order_btn {
  background: linear-gradient(180deg, #f88040 0, #e04040);
  box-shadow: 0 -0.5px 10px 2px rgba(238, 101, 64, 0.72) !important;
}

@media (min-width: 768px) {
  /* або інша ширина для десктопу */
  .shadow-focused {
    box-shadow: 0px 5px 9px 0 rgba(0, 0, 82, 0.1);
  }
  .shadow-normal {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
}

.border-right {
  border-right: 0 !important;
}

.border-left {
  border-left: 0 !important;
}
@media (max-width: 768px) {
  .border-left {
    border-left: 1px solid #ececf1 !important;
  }
  .border-right {
    border-right: 1px solid #ececf1 !important;
  }
}

.border-header {
  /* border-right: 1px  solid #ececf1  !important; */
  border-radius: 6px;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@media (max-width: 768px) {
  .alert {
    position: absolute;
    bottom: 100%;
    left: 50% !;
    transform: translateX(0%) translateY(5px) !important;
  }
}

.alert {
  position: absolute;
  bottom: 100%;
  transform: translateX(205%) translateY(-10px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: #222;
  color: white;
  border-radius: 10px;
  font-size: 14px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

a.tabs-item.active {
  @apply border-b-4 border-gray-400;
}

.range-slider-search .range-slider__range {
  background: #e8f1fa;
  height: 16px;
}

.range-slider-search .range-slider__thumb {
  width: 16px;
  height: 16px;
  background: rgb(34, 113, 209);
}

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.text-nowrap {
  text-wrap: nowrap;
}

.bg-shad-secondary {
  background-color: hsl(240 4.8% 95.9%);
}

.bg-shad-secondary:hover {
  background-color: hsl(240deg 4.8% 95.9% / 80%);
}

.text-shad-secondary {
  color: hsl(240 5.9% 10%);
}

.text-shad-secondary:hover {
  color: hsl(240 5.9% 10% / 80%);
}

.react-flow .react-flow__handle {
  left: 50%;
  top: 25%;
  transform: translate(-25%, -25%);
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.mdoc {
  h1 {
    @apply mb-6 text-2xl font-medium;
  }

  h2 {
    @apply mb-2 text-xl font-medium;
  }

  a {
    @apply text-primary;
  }

  hr {
    @apply my-4;
  }

  ul {
    @apply list-disc pl-4;
  }
}
