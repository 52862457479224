.floatInputWrapper{
    @apply outline outline-0 z-20 relative bg-white border border-gray-300 text-gray-400;
    @apply focus-within:border-blue-500 duration-300 ease-in;
}

.floatInput{
    @apply block p-3.5 w-full text-base focus:outline-none bg-transparent focus:text-black placeholder:text-transparent;
}

.floatInputLabel{
    @apply absolute left-0 ml-1 -translate-y-3 bg-white px-1 text-sm duration-100 ease-linear;
    @apply peer-placeholder-shown:translate-y-0 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500;
    @apply peer-focus:ml-1 peer-focus:-translate-y-3 peer-focus:px-1 peer-focus:text-sm;
}
