.status {
    @apply block w-4 h-4 min-w-[1rem] rounded-full border-[3px] border-alto;
}

.statusActive {
    @apply border-active;
}

.statusInActive {
    @apply border-inActive
}
