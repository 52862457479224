.mainInfoTable h3 {
    width: 178px;
}

.mainInfoTable h4 {
    width: 426px;
}

.mainInfoTable .officers {
    word-break: keep-all;
}

@media screen and (max-width: 500px) {
    .mainInfoTable h3 {
        width: 100%;
    }

    .mainInfoTable h4 {
        width: 100%;
    }

    .mainInfoTable .officers {
        word-break: break-all;
    }
}
