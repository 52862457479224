.fullOrderLabel:before {
    margin-right: 0.375rem;
    display: block;
    height: 1.5rem;
    width: 1.5rem;
    content: "";
    min-width: 24px;
    min-height: 24px;
    max-height: 24px;
    max-width: 24px;
    background-size: cover;
    background: url(/images/green-ok.svg) 50% no-repeat;
}

.fullOrderLabel{
    @apply flex pt-5 pb-3 text-green items-center break-normal
}
